// import AuthenticationLayout from '@/layouts/AuthenticationLayout.vue';
// import DashboardLayout from '@/layouts/DashboardLayout.vue';
import { useAccountStore } from '@/stores/account'
import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/AuthView.OLD.vue'
// import ProfileView from '../views/ProfileView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'active',
  // history: createMemoryHistory(),
  routes: [
    {
      path: '/',
      component: () => import('../layouts/AuthenticationLayout.vue'),
      children: [{
        path: '',
        name: 'Auth',
        component: () => import('../views/LogInView.vue')
      }]
    },
    {
      path: '/',
      component: () => import('../layouts/DashboardLayout.vue'),
      children: [
        {
          path: '/users',
          name: 'Users',
          component: () => import('../views/UsersView.vue'),
        },
        {
          path: '/users2',
          name: 'Users2',
          component: () => import('../views/Users2View.vue'),
        },
        {
          path: '/tracker',
          name: 'Tracker',
          component: () => import('../views/TrackerView.vue'),
          props: (route) => ({ userId: String(route.query.id ?? '') }),
        },
        {
          path: '/organizations',
          name: 'Organizations',
          component: () => import('../views/OrganizationsView.vue'),
        },
        // {
        //   path: '/test',
        //   name: 'Test',
        //   component: () => import('../views/TestView.vue'),
        // }
      ]
    },
    // {
    //   path: '/:catchAll(.*)*',
    //   component: () => import('pages/ErrorNotFound.vue'),
    // },
  ]
})

router.beforeResolve(async (to, from, next) => {
  const accountStore = useAccountStore();
  if (accountStore.accessToken && to.name === 'Auth') {
    return await next({ name: 'Users' })
  }
  if (!accountStore.accessToken && to.name !== 'Auth') {
    return await next({ name: 'Auth' })
  }
  return await next()
})

export default router
